.trail-mask[data-v-c3d98076] {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  user-select: none;
}
.contact-tele-container[data-v-c3d98076] {
  position: absolute;
  width: 298px;
  height: 80px;
  line-height: 80px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: 0 2px 8px 0 #0000000a;
  border-radius: 2px;
  text-align: center;
}
.contact-us[data-v-c3d98076] {
  font-size: 20px;
  vertical-align: middle;
}
.contact-tele-number[data-v-c3d98076] {
  color: #4677c6;
}
