.af-row {
  position: relative;
  flex: 1;
  /* 如果外层包裹了af-container， 需要这个自动撑开 */
}
.af-row::before,
.af-row::after {
  display: table;
  content: "";
}
.af-row::after {
  clear: both;
}
.af-row--flex {
  display: flex;
}
.af-row--flex:before,
.af-row--flex:after {
  display: none;
}
.af-row--flex.is-justify-center {
  justify-content: center;
}
.af-row--flex.is-justify-end {
  justify-content: flex-end;
}
.af-row--flex.is-justify-space-between {
  justify-content: space-between;
}
.af-row--flex.is-justify-space-around {
  justify-content: space-around;
}
.af-row--flex.is-align-middle {
  align-items: center;
}
.af-row--flex.is-align-bottom {
  align-items: flex-end;
}
