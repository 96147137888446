.af-container {
  display: flex;
  flex-direction: row;
  flex-basis: auto;
  box-sizing: border-box;
  min-width: 0;
}
.af-container > :not(.af-main) {
  flex-shrink: 0;
}
.is-vertical {
  flex-direction: column;
}
