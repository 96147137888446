.header-menu {
  list-style: none;
  white-space: nowrap;
  font-size: 0;
}
.header-menu-item-wrap {
  display: inline-block;
  position: relative;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  width: 168px;
  height: 70px;
}
.header-menu-item-wrap .show-child-box {
  width: 220px;
  height: 0px;
  overflow: hidden;
  -webkit-transition: height 0.3s;
  -moz-transition: height 0.3s;
  -o-transition: height 0.3s;
  transition: height 0.3s;
}
.header-menu-item-wrap:hover .show-child-box {
  height: auto;
  overflow: visible;
}
.header-menu-item-wrap:hover .show-child-box > div {
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.1);
}
.header-menu-item-wrap:hover .header-menu-item__arrow {
  transform: rotateX(180deg) translateY(6px);
}
.header-menu-item-wrap:hover .header-menu-item__hovertip {
  opacity: 1;
}
.header-menu-item-border {
  position: absolute;
  bottom: 9px;
  width: 50px;
  border-bottom: 3px solid #0f69fa;
  border-radius: 2px;
  transform: translateX(-12px);
  webkit-transition: left 0.3s;
  -moz-transition: left 0.3s;
  -o-transition: left 0.3s;
  transition: left 0.3s;
}
.header-menu-item {
  text-align: center;
  margin-top: 23px;
  line-height: 70px;
  font-size: 16px;
  color: #495060;
  cursor: pointer;
}
.header-menu-item__span {
  position: relative;
}
.header-menu-item__arrow {
  display: block;
  position: absolute;
  border: 5px solid rgba(255, 255, 255, 0);
  border-top-color: #495060;
  right: -14px;
  top: 9px;
  transition: all 0.2s;
}
.header-menu-item__hovertip {
  position: absolute;
  bottom: 9px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50px;
  border-bottom: 3px solid #0f69fa;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.1s;
}
.show-child-box {
  position: absolute;
  top: 70px;
  width: max-content;
  text-align: left;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
}
.show-child-box > div {
  margin-top: 5px;
  background: #fff;
  border-radius: 2px;
  padding: 16px 0;
}
.first-child {
  color: #8a93a8;
  font-size: 14px;
  padding: 20px 0 5px 0;
}
.second-child {
  width: 170px;
  height: 32px;
  line-height: 32px;
  color: #2f3748;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 10px;
}
.second-child:hover,
.second-child_active {
  color: #0f69fa;
  background: rgba(15, 105, 250, 0.06);
}
.second-child:first-child {
  margin-top: 0;
}
