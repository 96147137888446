.sf-card2[data-v-9f59c206] {
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  color: #303133;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.sf-card2 .sf-card--header[data-v-9f59c206] {
  padding: 15px 20px;
  font-size: 14px;
  color: #333;
}
.sf-card2 .sf-card--body[data-v-9f59c206] {
  padding: 20px 20px;
}
.sf-card2 .sf-card--header[data-v-9f59c206] {
  border-bottom: 1px solid #ebeef5;
}
