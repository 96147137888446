.x-img-wrapper[data-v-610919d0] {
  overflow: hidden;
}
.card-wrap[data-v-610919d0] {
  padding: 20px;
  background: #fff;
  font-size: 0;
}
.card-wrap img[data-v-610919d0] {
  width: 100%;
  height: auto;
  cursor: pointer;
  /*以防图片样式不规范破坏布局*/
  max-height: 205px;
  overflow: hidden;
}
.card-wrap p.card-title[data-v-610919d0] {
  font-size: 14px;
  margin-bottom: 16px;
}
.card-wrap p.card-desc[data-v-610919d0] {
  position: relative;
  cursor: pointer;
  top: -32px;
  line-height: 32px;
  font-size: 12px;
  background: rgba(29, 29, 29, 0.3);
  color: #fff;
  padding-left: 10px;
}
.card-wrap ul.msg-list[data-v-610919d0] {
  position: relative;
  top: -10px;
}
.card-wrap ul.msg-list li[data-v-610919d0] {
  font-size: 14px;
  color: #666;
  line-height: 30px;
  cursor: pointer;
}
.card-wrap ul.msg-list li[data-v-610919d0]:before {
  display: inline-block;
  content: '';
  height: 2px;
  width: 2px;
  border-radius: 50%;
  background-color: #666;
  position: relative;
  top: -3px;
  margin-right: 5px;
}
