.footer-wrap[data-v-04f207d8] {
  color: #8e9cae;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 12px;
  background: #e9ecef;
}
.footer-wrap a[data-v-04f207d8] {
  color: #8e9cae;
  text-decoration: underline;
}
.footer-wrap .cp[data-v-04f207d8] {
  margin-right: 32px;
}
.footer-wrap .split[data-v-04f207d8] {
  margin: 0 5px;
}
.footer-wrap--white[data-v-04f207d8] {
  background-color: #fff;
}
