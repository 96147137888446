.header-opr[data-v-5830ec15] {
  position: absolute;
  top: 18px;
  right: 0;
}
.header-opr__try[data-v-5830ec15] {
  width: 100px;
  height: 34px;
  border: none;
  outline: none;
  background: #0a69fa;
  border-radius: 2px;
  font-size: 13px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}
.header-opr-name[data-v-5830ec15] {
  height: 70px;
  line-height: 70px;
  min-width: 130px;
  color: #959db0;
  cursor: pointer;
}
.language-layer[data-v-5830ec15] {
  margin-top: 4px;
  border-radius: 2px;
}
.language-layer-item[data-v-5830ec15] {
  padding: 15px 0 0 21px;
  font-size: 12px;
  color: #2f3748;
  cursor: pointer;
}
.language-layer-item[data-v-5830ec15] :hover {
  color: #0f69fa;
}
