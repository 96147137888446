.select-btn[data-v-49d2f170] {
  display: inline-block;
  border: 1px solid #8791A7;
  border-radius: 13px;
  margin-right: 12px;
  padding: 3px 13px;
  font-size: 12px;
  color: #6b7488;
  cursor: pointer;
}
.select-btn.active[data-v-49d2f170] {
  color: #fff;
  border: none;
  padding-top: 4px;
  padding-bottom: 4px;
}
