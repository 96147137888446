[class*="af-col-"] {
  float: left;
  box-sizing: border-box;
}
.af-col-0 {
  display: none;
}
.af-col-24 {
  width: 100%;
}
.af-col-offset-24 {
  margin-left: 100%;
}
.af-col-pull-24 {
  position: relative;
  right: 100%;
}
.af-col-push-24 {
  position: relative;
  left: 100%;
}
.af-col-23 {
  width: 95.83333333%;
}
.af-col-offset-23 {
  margin-left: 95.83333333%;
}
.af-col-pull-23 {
  position: relative;
  right: 95.83333333%;
}
.af-col-push-23 {
  position: relative;
  left: 95.83333333%;
}
.af-col-22 {
  width: 91.66666667%;
}
.af-col-offset-22 {
  margin-left: 91.66666667%;
}
.af-col-pull-22 {
  position: relative;
  right: 91.66666667%;
}
.af-col-push-22 {
  position: relative;
  left: 91.66666667%;
}
.af-col-21 {
  width: 87.5%;
}
.af-col-offset-21 {
  margin-left: 87.5%;
}
.af-col-pull-21 {
  position: relative;
  right: 87.5%;
}
.af-col-push-21 {
  position: relative;
  left: 87.5%;
}
.af-col-20 {
  width: 83.33333333%;
}
.af-col-offset-20 {
  margin-left: 83.33333333%;
}
.af-col-pull-20 {
  position: relative;
  right: 83.33333333%;
}
.af-col-push-20 {
  position: relative;
  left: 83.33333333%;
}
.af-col-19 {
  width: 79.16666667%;
}
.af-col-offset-19 {
  margin-left: 79.16666667%;
}
.af-col-pull-19 {
  position: relative;
  right: 79.16666667%;
}
.af-col-push-19 {
  position: relative;
  left: 79.16666667%;
}
.af-col-18 {
  width: 75%;
}
.af-col-offset-18 {
  margin-left: 75%;
}
.af-col-pull-18 {
  position: relative;
  right: 75%;
}
.af-col-push-18 {
  position: relative;
  left: 75%;
}
.af-col-17 {
  width: 70.83333333%;
}
.af-col-offset-17 {
  margin-left: 70.83333333%;
}
.af-col-pull-17 {
  position: relative;
  right: 70.83333333%;
}
.af-col-push-17 {
  position: relative;
  left: 70.83333333%;
}
.af-col-16 {
  width: 66.66666667%;
}
.af-col-offset-16 {
  margin-left: 66.66666667%;
}
.af-col-pull-16 {
  position: relative;
  right: 66.66666667%;
}
.af-col-push-16 {
  position: relative;
  left: 66.66666667%;
}
.af-col-15 {
  width: 62.5%;
}
.af-col-offset-15 {
  margin-left: 62.5%;
}
.af-col-pull-15 {
  position: relative;
  right: 62.5%;
}
.af-col-push-15 {
  position: relative;
  left: 62.5%;
}
.af-col-14 {
  width: 58.33333333%;
}
.af-col-offset-14 {
  margin-left: 58.33333333%;
}
.af-col-pull-14 {
  position: relative;
  right: 58.33333333%;
}
.af-col-push-14 {
  position: relative;
  left: 58.33333333%;
}
.af-col-13 {
  width: 54.16666667%;
}
.af-col-offset-13 {
  margin-left: 54.16666667%;
}
.af-col-pull-13 {
  position: relative;
  right: 54.16666667%;
}
.af-col-push-13 {
  position: relative;
  left: 54.16666667%;
}
.af-col-12 {
  width: 50%;
}
.af-col-offset-12 {
  margin-left: 50%;
}
.af-col-pull-12 {
  position: relative;
  right: 50%;
}
.af-col-push-12 {
  position: relative;
  left: 50%;
}
.af-col-11 {
  width: 45.83333333%;
}
.af-col-offset-11 {
  margin-left: 45.83333333%;
}
.af-col-pull-11 {
  position: relative;
  right: 45.83333333%;
}
.af-col-push-11 {
  position: relative;
  left: 45.83333333%;
}
.af-col-10 {
  width: 41.66666667%;
}
.af-col-offset-10 {
  margin-left: 41.66666667%;
}
.af-col-pull-10 {
  position: relative;
  right: 41.66666667%;
}
.af-col-push-10 {
  position: relative;
  left: 41.66666667%;
}
.af-col-9 {
  width: 37.5%;
}
.af-col-offset-9 {
  margin-left: 37.5%;
}
.af-col-pull-9 {
  position: relative;
  right: 37.5%;
}
.af-col-push-9 {
  position: relative;
  left: 37.5%;
}
.af-col-8 {
  width: 33.33333333%;
}
.af-col-offset-8 {
  margin-left: 33.33333333%;
}
.af-col-pull-8 {
  position: relative;
  right: 33.33333333%;
}
.af-col-push-8 {
  position: relative;
  left: 33.33333333%;
}
.af-col-7 {
  width: 29.16666667%;
}
.af-col-offset-7 {
  margin-left: 29.16666667%;
}
.af-col-pull-7 {
  position: relative;
  right: 29.16666667%;
}
.af-col-push-7 {
  position: relative;
  left: 29.16666667%;
}
.af-col-6 {
  width: 25%;
}
.af-col-offset-6 {
  margin-left: 25%;
}
.af-col-pull-6 {
  position: relative;
  right: 25%;
}
.af-col-push-6 {
  position: relative;
  left: 25%;
}
.af-col-5 {
  width: 20.83333333%;
}
.af-col-offset-5 {
  margin-left: 20.83333333%;
}
.af-col-pull-5 {
  position: relative;
  right: 20.83333333%;
}
.af-col-push-5 {
  position: relative;
  left: 20.83333333%;
}
.af-col-4 {
  width: 16.66666667%;
}
.af-col-offset-4 {
  margin-left: 16.66666667%;
}
.af-col-pull-4 {
  position: relative;
  right: 16.66666667%;
}
.af-col-push-4 {
  position: relative;
  left: 16.66666667%;
}
.af-col-3 {
  width: 12.5%;
}
.af-col-offset-3 {
  margin-left: 12.5%;
}
.af-col-pull-3 {
  position: relative;
  right: 12.5%;
}
.af-col-push-3 {
  position: relative;
  left: 12.5%;
}
.af-col-2 {
  width: 8.33333333%;
}
.af-col-offset-2 {
  margin-left: 8.33333333%;
}
.af-col-pull-2 {
  position: relative;
  right: 8.33333333%;
}
.af-col-push-2 {
  position: relative;
  left: 8.33333333%;
}
.af-col-1 {
  width: 4.16666667%;
}
.af-col-offset-1 {
  margin-left: 4.16666667%;
}
.af-col-pull-1 {
  position: relative;
  right: 4.16666667%;
}
.af-col-push-1 {
  position: relative;
  left: 4.16666667%;
}
.af-col-0 {
  width: 0%;
}
.af-col-offset-0 {
  margin-left: 0%;
}
.af-col-pull-0 {
  position: relative;
  right: 0%;
}
.af-col-push-0 {
  position: relative;
  left: 0%;
}
