.mod-header {
  position: fixed;
  top: 0;
  z-index: 99;
  box-shadow: 0 1px 20px 0 rgba(165, 169, 177, 0.44);
  height: 70px;
  width: 100%;
  min-width: 1366px;
  background: #fff;
}
.header-content {
  position: relative;
  height: 70px;
  white-space: nowrap;
  font-size: 0;
}
.header-logo {
  height: 70px;
  display: inline-block;
  padding: 17px 0 0 0;
  cursor: pointer;
  white-space: normal;
  margin-right: 62px;
}
.header-logo-img {
  float: left;
}
.header-logo-img img {
  width: 128px;
}
.header-logo-text {
  width: 80%;
  float: right;
  line-height: 15px;
  padding-top: 4px;
}
.header-logo-text .header-logo-text-big {
  vertical-align: middle;
  font-size: 18px;
  letter-spacing: 0.5px;
  font-weight: bold;
  color: #495060;
}
.header-logo-text .header-logo-text-small {
  font-size: 11px;
  color: #ccc;
}
.service-content {
  position: relative;
  height: 100%;
}
.mod-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  max-width: 1920px;
  z-index: 12;
  color: #000;
  background: #fff;
}
