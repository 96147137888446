.information-banner[data-v-0f7b8a4c] {
  height: 500px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-position: center center;
}
.information-banner div[data-v-0f7b8a4c] {
  width: 100%;
  position: absolute;
  top: 50%;
  margin-top: -68px;
  text-align: center;
}
.information-banner div .title[data-v-0f7b8a4c] {
  font-weight: bold;
  font-size: 50px;
  color: #fff;
  letter-spacing: 0;
}
.information-banner div .desc[data-v-0f7b8a4c] {
  font-size: 30px;
  color: #fff;
  margin-top: 30px;
}
