.every-year-warp[data-v-98e30118] {
  position: relative;
  left: 580px;
}
.every-year-warp .big-circle[data-v-98e30118] {
  display: inline-block;
  width: 111px;
  height: 111px;
  border-radius: 50%;
  position: relative;
  margin-bottom: 10px;
}
.every-year-warp .big-circle .small-circle[data-v-98e30118] {
  position: relative;
  top: 31px;
  left: 31px;
  width: 49px;
  height: 49px;
  border-radius: 50%;
}
.every-year-warp .big-circle .small-circle .year-text[data-v-98e30118] {
  font-family: DINPro;
  font-size: 30px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.detail-list-item div.detail-content[data-v-98e30118] {
  background: #fff;
  border: 1px solid #f5f5f5;
  box-shadow: 0 14px 26px 0 rgba(107, 107, 107, 0.06);
  width: 556px;
  padding: 20px 30px;
  position: relative;
  cursor: pointer;
}
.detail-list-item div.detail-content img[data-v-98e30118] {
  position: absolute;
  max-width: 500px;
  display: none;
  top: -280px;
}
.detail-list-item div.detail-content p.title[data-v-98e30118] {
  ont-family: PingFangSC-Semibold;
  font-size: 26px;
  color: #495060;
  line-height: 33px;
}
.detail-list-item div.detail-content p.content[data-v-98e30118] {
  font-size: 14px;
  color: #8C95AB;
  line-height: 19px;
  margin-top: 12px;
}
.detail-list-item div.detail-content:hover img[data-v-98e30118] {
  display: block;
}
.detail-list-item div.detail-content.left[data-v-98e30118] {
  margin-left: -580px;
  margin-top: -80px;
}
.detail-list-item div.detail-content.right[data-v-98e30118] {
  margin-left: 138px;
  margin-top: -80px;
}
.detail-list-item .detail-big-circle[data-v-98e30118] {
  width: 43px;
  height: 43px;
  border-radius: 50%;
  position: relative;
  left: 34px;
  margin-bottom: 10px;
}
.detail-list-item .detail-big-circle .detail-small-circle[data-v-98e30118] {
  position: relative;
  top: 17px;
  left: 17px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}
span.link-line[data-v-98e30118] {
  display: inline-block;
  width: 2px;
  height: 100px;
  position: relative;
  left: 54.5px;
  margin-bottom: 10px;
}
div.open-more-btn[data-v-98e30118] {
  position: relative;
  left: 585px;
  margin-top: 10px;
  background: #eef4ff;
  border: 1px solid #b7cfff;
  border-radius: 17px;
  color: #4677c6;
  width: 100px;
  text-align: center;
  padding: 5px 0;
  cursor: pointer;
}
.big-circle-red[data-v-98e30118] {
  background: rgba(238, 85, 85, 0.06);
}
.big-circle-red > div[data-v-98e30118] {
  background: rgba(238, 85, 85, 0.06);
}
.big-circle-red > div .year-text[data-v-98e30118] {
  color: #e55;
}
.big-circle-orange[data-v-98e30118] {
  background: rgba(255, 153, 0, 0.05);
}
.big-circle-orange > div[data-v-98e30118] {
  background: rgba(255, 153, 0, 0.08);
}
.big-circle-orange > div .year-text[data-v-98e30118] {
  color: #f90;
}
.detail-big-circle-red[data-v-98e30118] {
  background: #fef5f5;
}
.detail-big-circle-red > div[data-v-98e30118] {
  background: #e55;
}
.detail-big-circle-orange[data-v-98e30118] {
  background: rgba(255, 153, 0, 0.05);
}
.detail-big-circle-orange > div[data-v-98e30118] {
  background: #f90;
}
.detail-link-line-red[data-v-98e30118] {
  background-color: #ff9595;
}
.detail-link-line-orange[data-v-98e30118] {
  background-color: #f90;
}
