.container[data-v-c153352e] {
  /*max-width: 1920px;*/
  min-height: 100vh;
  margin: 0 auto;
  border-top: 1px solid transparent;
}
.container > .main[data-v-c153352e] {
  margin-top: 70px;
  position: relative;
  min-height: calc(100% - 60px);
}
