.intro__top-btn[data-v-592661f7] {
  position: fixed;
  bottom: 10%;
  right: 1.5%;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 1px 10px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 999;
}
.top-btn__arrow[data-v-592661f7] {
  position: absolute;
  margin-top: 8px;
  margin-left: 15px;
  width: 26px;
  height: 26px;
  border: 13px solid;
  border-radius: 4px;
  border-color: transparent transparent #ddd transparent;
}
.top-btn__arrow[data-v-592661f7]:after {
  content: '';
  position: absolute;
  left: -13px;
  top: -9px;
  width: 26px;
  height: 26px;
  border: 13px solid;
  border-color: transparent transparent #fff transparent;
}
