/*=================================================================================
 *
 *   重置系统默认样式
 *
 *=================================================================================*/
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
p {
  margin: 0;
}
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: #4677C6;
}
/*
  全局公共样式
*/
/* 颜色变量定义 */
/*
 样色定义
*/
.color-orange {
  color: #f90;
}
.color-red {
  color: #e55;
}
.color-blue {
  color: #4d8dd9;
}
.color-green {
  color: #22B07B;
}
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.equal-parent-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.content-padding-space {
  padding: 20px 20px 20px 20px;
}
.content-margin-space {
  margin: 20px 20px 20px 20px;
}
.content-center-wrapper {
  width: 1280px;
  margin: 0 auto;
}
.text-arrow-base {
  vertical-align: 0;
  font-size: 1em;
}
.text-arrow-up:before {
  content: '\2191';
  vertical-align: 0;
  font-size: 1em;
}
.text-arrow-down:before {
  content: '\2193';
  vertical-align: 0;
  font-size: 1em;
}
.link-blue {
  color: #4677c6;
  cursor: pointer;
}
.link-blue:hover {
  opacity: 0.8;
}
.link-blue:active {
  opacity: 1;
}
.grid-separator {
  display: inline-block;
  width: 2px;
  height: 12px;
  background-color: #ddd;
  vertical-align: middle;
}
#sfMask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 1010;
}
#sfMask.sfv-mask-position {
  display: block;
}
.sfv-mask {
  background-color: rgba(155, 155, 155, 0.6);
}
.bottom-bar {
  border: 1.5px solid #0f69fa;
  border-radius: 2px;
}
.page-content-wrap {
  padding: 40px 50px;
}
.x-img {
  height: 0;
  padding-bottom: 55.2%;
  background-size: cover;
}
.x-link {
  color: #4677C6;
  cursor: pointer;
}
.information__main-content--panel {
  position: relative;
  margin-top: -2px;
  z-index: 1;
}
.information__main-content--panel .sfv-tabpanel_header {
  padding-top: 0;
}
.information__main-content--panel .sfv-tabpanel_header button {
  width: auto !important;
  min-width: auto !important;
  height: 56px !important;
  padding: 0 !important;
}
.information__main-content--panel .sfv-tabpanel_header button::after {
  width: 96% !important;
  left: 2% !important;
  margin-left: 0 !important;
}
.information__main-content--panel .sfv-tabpanel_header button:last-child {
  margin-left: 38px;
}
.information__main-content--panel .sfv-tabpanel_wrap {
  padding: 20px 0;
  background: #e9ecef;
}
.over-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hover-zoom {
  transform-origin: center center;
  transition: transform 0.5s;
}
.hover-zoom:hover {
  transform: scale(1.2);
}
.hover-shadow {
  transition: all 0.5s;
}
.hover-shadow:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}
.hover-shadow-only {
  transition: all 0.5s;
}
.hover-shadow-only:hover {
  box-shadow: 0 3px 6px 0 rgba(153, 110, 110, 0.13);
}
.hover-scale-shadow {
  transform-origin: center center;
  transition: all 0.5s;
}
.hover-scale-shadow:hover {
  transform: scale(1.02) translateY(-5px);
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.15);
}
.anim-from-left {
  animation-name: from-left;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;
}
@keyframes from-left {
  0% {
    transform: translateX(-50%);
    opacity: 0.1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
.anim-from-transparent {
  animation-name: from-transparent;
  animation-fill-mode: backwards;
  animation-duration: 0.5s;
}
@keyframes from-transparent {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hover-link {
  cursor: pointer;
  transition: all 0.5s;
}
.hover-link:hover {
  color: #4677C6 !important;
  transform: translateX(5px);
}
.sfv-toolbar_inner .sfv-btn {
  min-width: 65px;
  padding: 0 10px;
  border: none;
}
.mssp-grid .sfv-pagination__wrapper {
  display: flex;
  flex-direction: row-reverse;
  height: 50px;
}
.mssp-grid .mssp-grid-toolbar .sfv-form-item {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}
.mssp-grid .sfv-table .sfv-table_header-row .language-wrap {
  margin-right: 16px;
}
.sf-slider {
  overflow: visible;
}
.sf-slider-item {
  overflow: visible;
}
.sfv-nav-sub-menu_title_toggle-icon-wrapper {
  display: none;
}
.sf-slider-btn-ul {
  bottom: 142px;
}
.sf-slider-btn-ul .sf-slider-btn-li.sf-slider-btn-li--ative {
  background-color: #fff;
}
.background-white {
  background: #fff;
}
.sfv-tabpanel_header {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid #e9ecef;
}
.sfv-tabpanel_header .sfv-tabpanel_item {
  background-color: transparent;
  height: 60px;
}
.sfv-tabpanel_header .sfv-tabpanel_item--active {
  position: relative;
  color: #0f69fa;
}
.sfv-tabpanel_header .sfv-tabpanel_item--active:after {
  display: inline-block;
  height: 2px;
  width: 40%;
  content: '';
  position: absolute;
  bottom: 0px;
  top: auto;
  left: 50%;
  margin-left: -20%;
  background-color: #0f69fa;
}
.pagination-wrapper {
  height: 70px;
  padding-top: 13px;
  border-top: 1px solid #e9ecef;
}
.sfv-pagination__wrapper {
  margin-bottom: 2px;
  border-top: none;
}
.sfv-pager__wrapper {
  width: 100%;
  text-align: center;
}
.sfv-pager__wrapper .sfv-pager-total {
  display: none;
}
.sfv-pager__content li.sfv-pager__item {
  margin-left: 10px;
  border: none !important;
  background: #f0f2f5 !important;
}
.sfv-pager__content li.sfv-pager__item.active {
  background: #2568de !important;
}
.sfv-pager__content li.sfv-pager-jump__first,
.sfv-pager__content li.sfv-pager-jump__last {
  display: none;
}
.page-size {
  display: none;
}
.scroll-auto {
  overflow: auto;
  height: 100%;
}
.sfv-panel_body::-webkit-scrollbar,
.sfv-tabpanel_wrap::-webkit-scrollbar,
.scroll-auto::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  background-color: #fff;
  cursor: pointer;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border-color: #eee;
  border-radius: 10px;
}
.sfv-panel_body::-webkit-scrollbar-track,
.sfv-tabpanel_wrap::-webkit-scrollbar-track,
.scroll-auto::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
  transition: all 0.5s ease;
  border-top: 1px solid #eee;
}
.sfv-panel_body::-webkit-scrollbar-thumb,
.sfv-tabpanel_wrap::-webkit-scrollbar-thumb,
.scroll-auto::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc;
}
.sfv-breadcrumb {
  height: 30px;
  line-height: 30px;
  margin: 0;
}
.sfv-breadcrumb-item a {
  color: #4677C6;
}
.sfv-breadcrumb-item:last-child {
  color: #999;
}
.sfv-radio-wrap:after {
  background-color: #0f69fa;
}
/**
 * 布局相关的样式
 */
[class*="af-flex"] {
  display: flex;
}
.af-flex-justify-center {
  justify-content: center;
}
.af-flex-justify-end {
  justify-content: flex-end;
}
.af-flex-justify-space-between {
  justify-content: space-between;
}
.af-flex-justify-space-around {
  justify-content: space-around;
}
.af-flex-align-middle {
  align-items: center;
}
.af-flex-align-bottom {
  align-items: flex-end;
}
@font-face {
  font-family: 'DINPro';
  src: url(/static/fonts/dinregularwebfont.ad115e7.eot) format('embedded-opentype'), url(/static/fonts/dinregularwebfont.8910bbd.woff) format('woff'), url(/static/fonts/dinregularwebfont.9adeea1.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  min-height: 100vh;
}
body {
  position: relative;
  overflow: auto;
  font: 10pt "Microsoft Yahei", Helvetica, Arial, "PingFang SC", "Simsun", serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  color: #333;
  background-color: #e9ecef;
}
